import JustValidate from 'just-validate';

const darkModeToggleButton = document.getElementById('darkModeToggle');
const darkModeIconSpan = document.getElementById('darkModeIcon');
const lightModeIconSpan = document.getElementById('lightModeIcon');

// Check if 'darkMode' item exists in localStorage and use it if available
const hasDarkModeLocalStorage = localStorage.getItem('darkMode') !== null;
let isDarkMode;

if (hasDarkModeLocalStorage) {
  isDarkMode = localStorage.getItem('darkMode') === 'true';
} else {
  // Use system preference if localStorage is not set
  const prefersDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  isDarkMode = prefersDarkMode;
}

// Function to set dark mode
function setDarkMode(isDark) {
  document.body.classList.toggle('dark-mode', isDark);
  localStorage.setItem('darkMode', isDark);

  // Toggle icon visibility
  if (isDark) {
    darkModeIconSpan.style.display = 'none';
    lightModeIconSpan.style.display = 'block';
  } else {
    lightModeIconSpan.style.display = 'none';
    darkModeIconSpan.style.display = 'block';
  }
}

// Initialize based on localStorage or system preference
setDarkMode(isDarkMode);

// Toggle dark mode on button click
darkModeToggleButton.addEventListener('click', () => {
  isDarkMode = !isDarkMode; // Toggle dark mode
  setDarkMode(isDarkMode);
});

function submitNewsletterForm(event) {
  const form = document.getElementById('newsletter-form');
  const successMessage = document.getElementById('newsletter-success-message');
  const errorMessage = document.getElementById('newsletter-error-message');
  const emailAlreadySubscribedMessage = document.getElementById('newsletter-email-already-subscribed-message');

  const email = form.querySelector('input[name="newsletterEmail"]').value;
    fetch('/newsletter/subscribe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        email: email
      })
    })
      .then(response => response.json())
      .then(data => {
        if(data.code === 'MEMBER_EXISTS_WITH_EMAIL_ADDRESS') {
          form.style.display = 'none';
          emailAlreadySubscribedMessage.style.display = 'block';
        } else if (data.status === 'PENDING') {
          form.style.display = 'none';
          successMessage.style.display = 'block';
        } else {
          form.style.display = 'none';
          errorMessage.style.display = 'block'; // Show the error message
        }
      })
      .catch(error => {
        form.style.display = 'none';
        errorMessage.style.display = 'block'
      });
}

const validator = new JustValidate('#newsletter-form', {
  errorFieldCssClass: ['form__input-error'],
  errorLabelCssClass: ['form__label-error']
});

validator
  .addField('#newsletterEmail', [
    {
      rule: 'required',
      errorMessage: "Le courriel est requis"
    },
    {
      rule: 'email',
      errorMessage: "Le courriel n'est pas valide"
    },
  ])
  .onSuccess(event => {
    submitNewsletterForm(event);
  });
